import moment from 'moment';

const formatDate = (dateString) => {
    if (!dateString || !moment(dateString).isValid()) {
        return '';
    }

    const date = moment(dateString);

    const diffInDays = moment().diff(date, 'days');
    
    if (diffInDays < 2) {
        return diffInDays === 0 ? 'Today' : `Yesterday (${date.format('MM/DD/YYYY')})`;
    }
    
    return `${date.fromNow()} (${date.format('MM/DD/YYYY')})`;
};

export default formatDate;