import { Cell } from 'baseui/layout-grid';
import { RadioGroup, Radio } from 'baseui/radio';
import { FormControl } from 'baseui/form-control';

const SalaryTabs = ({ frequency, handleChange }) => {
  return (
    <div style={{ display: 'none' }}>
      <Cell span={[12, 6]}>
        <FormControl label="Compensation frequency">
          <RadioGroup
            name="salaryFrequency"
            value={frequency}
            onChange={handleChange}
          >
            <Radio value="hourly">Hourly</Radio>
            <Radio value="monthly">Monthly</Radio>
            <Radio value="annually">Annually</Radio>
          </RadioGroup>
        </FormControl>
      </Cell>
    </div>
  );
};

export default SalaryTabs;