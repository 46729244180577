import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { theme } from './theme/theme';
const queryClient = new QueryClient()

const engine = new Styletron();
import App from './App'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>

          <BrowserRouter basename={import.meta.env.VITE_PUBLIC_PATH as string}>
            <App />
          </BrowserRouter>
        </BaseProvider>
      </StyletronProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)
