import { HeadingLarge } from "baseui/typography";
import { Jobs } from "@/components/jobs";

const Home = () => (
    <>
        <HeadingLarge>Jobs</HeadingLarge>
        <Jobs />
    </>
)

export default Home;