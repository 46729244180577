import { Block } from 'baseui/block';
import { Spinner } from "baseui/spinner";
import useJobStore from '../../store/jobs';
import { useGet } from '../../hooks/apiHooks';
import { JobCard } from './job';
import { JobResponse } from '@/network/responses/job';

export const Jobs = () => {
    const {
        error,
        isLoading,
        data: {
            agencyJobs: jobs = []
        } = {},
    } = useGet<JobResponse>('agency-api/jobs');

    const setJobs = useJobStore(state => state.setJobs)

    useEffect(() => {
        if (jobs) {
            setJobs(jobs);
        }
    }, [jobs])

    if (error) {
        return <span>Error loading jobs!</span>
    }

    if (isLoading)  {
        return <Spinner />
    }

    return (
        <Block>
            {jobs.map((job) => <JobCard pointerCursor key={job.id} job={job} />)}
        </Block>
    )
}