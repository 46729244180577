import { ChevronLeft } from "baseui/icon";
import { useNavigate } from "react-router-dom"
import { HeadingXSmall } from "baseui/typography";

export interface NavigateBackProps {
    route: string | null;
    text: string;
}

export const NavigateBack = ({
    route = null,
    text = 'Go Back'
}: NavigateBackProps) => {
    const navigate = useNavigate();
    return (
        <HeadingXSmall
            onClick={() => {
                if (route) {
                    navigate(route);
                } else {
                    navigate(-1)
                }
            }}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
            <ChevronLeft $size={24} style={{ marginRight: '8px' }} />
            {text}
        </HeadingXSmall>
    )
}