import { useState } from 'react';
import { FormControl } from 'baseui/form-control';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { ParagraphSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { Cell } from 'baseui/layout-grid';

const ResumeUpload = ({ setResume, errors }) => {
  const [css, theme] = useStyletron();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [_, setError] = useState<string | null>(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const validExtensions = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    if (file && validExtensions.includes(file.type)) {
      setSelectedFile(file);
      setResume(file);
      setError(null);
    } else {
      setSelectedFile(null);
      setError('Please upload a valid .docx or .pdf file.');
    }
  };

  return (
    <Cell span={[12, 6]}>
      <FormControl error={errors}>
        <Block
          display="flex"
          alignItems="center"
          padding={theme.sizing.scale500}
          backgroundColor={theme.colors.mono200}
          overrides={{
            Block: {
              style: {
                borderRadius: theme.borders.radius200,
              },
            },
          }}
        >
          <input
            type="file"
            id="resume-upload"
            accept=".pdf, .docx"
            onChange={handleFileChange}
            className={css({ display: 'none' })}
          />
          <label htmlFor="resume-upload">
            <Button
              kind="primary"
              onClick={() => document.getElementById('resume-upload')?.click()}
              overrides={{
                BaseButton: {
                  style: {
                    backgroundColor: theme.colors.black,
                    color: theme.colors.white,
                    borderRadius: theme.borders.radius400,
                  },
                },
              }}
            >
              Browse files
            </Button>
          </label>
          <Block
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingLeft={theme.sizing.scale500}
          >
            {selectedFile ? (
              <ParagraphSmall>{selectedFile.name || 'No file selected'}</ParagraphSmall>
            ) : (
              'Upload candidate\'s resume'
            )}
          </Block>
        </Block>
      </FormControl>
    </Cell>
  );
};

export default ResumeUpload;
