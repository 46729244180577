import React from 'react';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Select, Option, Value } from 'baseui/select';
import { Textarea } from 'baseui/textarea';
import { PhoneInput, COUNTRIES } from 'baseui/phone-input';
import { Block } from 'baseui/block';
import { Cell } from 'baseui/layout-grid';
import { RadioGroup, Radio } from 'baseui/radio';
import CustomFlag from './custom-flag';
import SalaryTabs from './salary-tabs';

export interface FormData {
    name: string;
    email: string;
    phone: string;
    countryId: Option[];
    city: string;
    developerType: Option[];
    salaryCurrency: string;
    salaryFrequency: string;
    expectedSalary: string;
    yearsOfExperience: string;
    noticePeriod: string;
    note: string;
}

interface FormControlsProps {
    formData: FormData;
    countries: Option[];
    errors: Record<string, string>;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSelectChange: (name: keyof FormData, value: Value) => void;
    currency: string;
}

const FormControls: React.FC<FormControlsProps> = ({
    formData,
    countries,
    errors,
    handleChange,
    handleSelectChange,
    currency
}) => {
    const [phoneCountry, setPhoneCountry] = React.useState(
        COUNTRIES.US
    );
    return (
        <>
            <Cell span={[6, 12]}>
                <FormControl label="">
                    <span />
                </FormControl>
            </Cell>

            <Cell span={[12, 6]}>
                <FormControl label="Name" error={errors.name}>
                    <Input
                        name="name"
                        placeholder="Enter candidate's name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </FormControl>
            </Cell>

            <Cell span={[12, 6]}>
                <FormControl label="Email" error={errors.email}>
                    <Input
                        name="email"
                        placeholder="Enter candidate's email"
                        value={formData.email}
                        type="email"
                        onChange={handleChange}
                    />
                </FormControl>
            </Cell>

            <Cell span={[12, 6]}>
                <FormControl label="Country" error={errors.countryId}>
                    <Select
                        options={countries.map((country) => ({
                            label: country.name,
                            id: country.id,
                            currency: country.currency
                        }))}
                        value={formData.countryId}
                        onChange={(params) => handleSelectChange('countryId', params.value)}
                        placeholder="Select a country"
                    />
                </FormControl>
            </Cell>

            <Cell span={[12, 6]}>
                <FormControl label="City (Optional)">
                    <Input
                        name="city"
                        placeholder="Enter city"
                        value={formData.city}
                        onChange={handleChange}
                    />
                </FormControl>
            </Cell>

            <Cell span={[12, 6]}>
                <FormControl label="Mobile phone number" error={errors.phone}>
                    <PhoneInput
                        country={phoneCountry}
                       
                        onCountryChange={({ option }) => {
                            // @ts-ignore
                            const numericValue = `${option.dialCode}${formData.phone.replace(phoneCountry.dialCode, '').replace(/\D/g, '')}`;
                            // Update the country and reapply the dial code with the current number
                            // @ts-ignore
                            setPhoneCountry(option);
                            // @ts-ignore
                            handleChange({ target: { name: 'phone', value: numericValue } })
                        }}
                        text={formData.phone.replace(phoneCountry.dialCode, '')}
                        // @ts-ignore
                        onTextChange={(e) => {
                            const phoneNumber = `${phoneCountry.dialCode}${e.target.value.replace(/\D/g, '')}`;
                             // @ts-ignore
                            handleChange({ ...e, target: { name: 'phone', value: phoneNumber } })
                        }}
                        overrides={{
                            FlagContainer: {
                                component: CustomFlag,
                            },
                        }}
                    />
                </FormControl>
            </Cell>

            <Cell span={[12, 6]}>
                <FormControl label="">
                        <span />
                </FormControl>
            </Cell>            

            <Cell span={[12, 6]}>
                <FormControl label="Type of engagement with Turing  " error={errors.developerType}>
                    <Select
                        options={[
                            { label: 'Full-time Employee', id: 'fte' },
                            { label: 'Contractor', id: 'contractor' },
                            { label: 'Sub-contractor', id: 'sub_contractor' },
                        ]}
                        value={formData.developerType}
                        onChange={(params) => handleSelectChange('developerType', params.value)}
                        placeholder="Select type of developer"
                    />
                </FormControl>
            </Cell>

            <Cell span={[12, 6]}>
                <FormControl label="Years of experience" error={errors.yearsOfExperience}>
                    <Input
                        type="number"
                        name="yearsOfExperience"
                        placeholder="Enter years of experience"
                        value={formData.yearsOfExperience}
                        onChange={handleChange}
                    />
                </FormControl>
            </Cell>

            <Cell span={[12, 6]}>
                <FormControl label="Notice period">
                    <RadioGroup
                        name="noticePeriod"
                        value={formData.noticePeriod}
                        onChange={handleChange}
                    >
                        <Radio value="0">Immediate</Radio>
                        <Radio value={formData.noticePeriod !== '0' ? formData.noticePeriod : ''}>
                            <Block display="flex" alignItems="center">
                                <Input
                                    name="noticePeriod"
                                    placeholder="2"
                                    value={formData.noticePeriod !== '0' ? formData.noticePeriod : ''}
                                    onChange={handleChange}
                                    type="number"
                                    min={1}
                                    max={52}
                                    overrides={{
                                        Root: {
                                            style: { width: '80px', marginRight: '8px' },
                                        },
                                    }}
                                />
                                weeks
                            </Block>
                        </Radio>
                    </RadioGroup>
                </FormControl>
            </Cell>


            <SalaryTabs
                frequency={formData.salaryFrequency}
                handleChange={handleChange}
            />
    
            <Cell span={[12, 6]}>
                <FormControl label={`Expected salary (${formData.salaryFrequency})`} error={errors.expectedSalary}>
                    <Input
                        name="expectedSalary"
                        placeholder={`Enter expected salary`}
                        value={formData.expectedSalary}
                        onChange={handleChange}
                        endEnhancer={() => currency}
                    />
                </FormControl>
            </Cell>

            <Cell span={[12, 6]}>
                <FormControl label="Note">
                    <Textarea
                        name="note"
                        placeholder="Add skills, and any other information about the developer"
                        value={formData.note}
                        onChange={handleChange}
                    />
                </FormControl>
            </Cell>
        </>
    )
};

export default FormControls;