import React from 'react';
import { useParams } from "react-router-dom";
import { Card } from "baseui/card";
import { Tabs, Tab } from 'baseui/tabs';
import { Block } from 'baseui/block';
import { Button } from "baseui/button";
import { StyledLink } from 'baseui/link';
import { UserPlusIcon } from "@/assets/icons/user-plus";
import { useNavigate } from "react-router-dom";
import useJobStore from "../../store/jobs";
import { JobCard } from "../../components/jobs/job";
import { NavigateBack } from "../../components/navigation/navigate-back";
import CandidateTable from '../../components/job-candidates-list/candidate-table';
import { useGet } from '@/hooks/apiHooks';
import { JobCandidateResponse } from '@/network/responses/job';

interface AddCandidateButtonProps {
    jobId: number;
}

const AddCandidateButton = ({ 
    jobId 
}: AddCandidateButtonProps) => {
    const navigate = useNavigate();
    return (
        <Button
            onClick={() => navigate(`/job/${jobId}/add-candidate`)}
            overrides={{
                BaseButton: {
                    style: ({ $theme }) => ({
                        paddingLeft: $theme.sizing.scale600,
                        paddingRight: $theme.sizing.scale600,
                        borderRadius: $theme.borders.radius400, // Rounded corners
                    }),
                },
            }}
            startEnhancer={() => <UserPlusIcon />} // Icon on the left
        >
            Add a candidate
        </Button>
    );
};

const JobDetailsTabs = ({job}) => {
  const [activeKey, setActiveKey] = React.useState('0');
  const [loading, setLoading] = React.useState(true);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [candidateFilter, setCandidateFilter] = React.useState('all');
  const { id = null } = useParams();
  const pageSize = 20;

  const result = useGet<JobCandidateResponse>(
    `agency-api/job/${id}/candidates?filter=${candidateFilter}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    { filter: candidateFilter, pageNumber, pageSize } // Params
  );

  const data = result.data?.data || []; // Safely access the data
  const paginationData = result.data?.paginationData || { totalPages: 1 }; // Safely access paginationData
  const success = result.data?.success || false; // Safely access success flag

  const changeSelectedFilter = (filter: string) => {
    setLoading(true)
    setCandidateFilter(filter);
  };

  const changePage = (page: number) => {
    setLoading(true)
    setPageNumber(page);
  };

  React.useEffect(() => {
    if (success || data) {
      setLoading(false);
    }
  }, [data, success]);

    return (
        <Card
            overrides={{
                Root: {
                    style: ({ $theme }) => ({
                        backgroundColor: $theme.colors.backgroundSecondary, // Customize the color
                        padding: $theme.sizing.scale100,
                        minWidth: "100%",
                        width: "100%",
                    }),
                },
            }}
        >
            <Tabs
                activeKey={activeKey}
                onChange={({ activeKey }) => {
                    // @ts-ignore
                    setActiveKey(activeKey);
                }}
                overrides={{
                    TabBar: {
                      style: ({ $theme }) => ({
                        borderBottom: `2px solid ${$theme.colors.backgroundPrimary}`,
                        backgroundColor: $theme.colors.backgroundSecondary
                      })
                    }
                  }}
            >
                <Tab title="Job details">
                    <Block
                        padding="scale100"
                    >
                        {job.agenciesJobDescriptionLink ? (
                            <StyledLink
                                href={job.agenciesJobDescriptionLink}
                                target="_blank"
                                $style={({ $theme }) => ({
                                    fontSize: $theme.typography.LabelLarge.fontSize,
                                    color: $theme.colors.accent,
                                    fontWeight: 'bold',
                                })}
                            >
                                Job Description Link
                            </StyledLink>
                        ) : 'Job description link is not provided'}
                    </Block>
                </Tab>
                <Tab title="Candidates">
                    <CandidateTable
                        changeSelectedFilter={changeSelectedFilter}
                        loading={loading}
                        data={data}
                        totalPages={paginationData.totalPages}
                        changePage={changePage}
                    />
                </Tab>
            </Tabs>
        </Card>
    );
};





const JobDetails = () => {
    const { id = null } = useParams();
    if (!id) return null;
    const getJobById = useJobStore(state => state.getJobById);
    const job = getJobById(Number(id));
    if (!job) return;
    return (
        <>
            <NavigateBack 
                route={`/jobs`}
                text="Back to job board"
            />
            <JobCard
                job={job}
                actionComponent={<AddCandidateButton jobId={job.id}/>}
                onCardClick={() => { }}
            />
            <JobDetailsTabs job={job}/>
        </>
    )
}

export default JobDetails;