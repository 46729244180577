import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { StyledLink } from "baseui/link";
import useAuthStore from '@/store/auth';
import { usePost } from '@/hooks/apiHooks';

const Header = () => {
  const [css, theme] = useStyletron();
  const logout = usePost("agency-api/logout")
  const logOut = useAuthStore(state => state.logout)

  return (
    <Block
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingLeft={theme.sizing.scale800}
      paddingRight={theme.sizing.scale800}
      paddingTop={theme.sizing.scale600}
      paddingBottom={theme.sizing.scale600}
      backgroundColor="#fff"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      style={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        zIndex: 10
      }}
    >
      {/* Left side: Logo */}
      <Block display="flex" alignItems="center">
        <img
          src="/turing-logo.png"
          alt="Turing"
          className={css({
            height: '24px',
            marginRight: theme.sizing.scale200,
            [theme.mediaQuery.small]: {
              height: '20px', // Adjust logo size on smaller screens
            },
          })}
        />
      </Block>

      {/* Right side: Logout and dropdown */}
      <Block display="flex" alignItems="center" marginRight="40px">
        <StyledLink
          className={css({
            marginRight: theme.sizing.scale200,
            fontSize: theme.typography.font400.fontSize,
          })}
          style={{
            cursor: "pointer",
            textDecoration: "none",
          }}
          onClick={() => {
            logout.mutate({}, {
              onSuccess: () => {
                logOut()
              },
              onError: () => {
                logOut()
              }
            })
          }}
        >
          Logout
        </StyledLink>
      </Block>
    </Block>
  );
};

export default Header;