import { create, StateCreator } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

/**
 * Common store utility to handle state and local storage with encryption/decryption.
 * @param storeName 
 * @param encryption 
 * @param store 
 * @returns 
 */
export const useStore = <T>(storeName: string, storage: boolean, store: StateCreator<T, [], []>) => {
  const env = import.meta.env.NODE_ENV as string;
  const enhancedStore = env === 'developement' ? devtools(store, { name: storeName }) : store;
  
  return create<T>(
    (storage ? persist(
      enhancedStore as StateCreator<T, [], []>,
      {
        name: storeName,
        getStorage: () => localStorage,
      }
    )
    : enhancedStore) as StateCreator<T, [], []>
  );
}
