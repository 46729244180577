import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetcher } from '@/network/api/fetcher';

type QueryParams = Record<string, string | number | boolean>;

export const useGet = <T>(url: string, params?: QueryParams) => {
    return useQuery<T, Error>({
      queryKey: ['get', { url, ...params }],
      queryFn: () => fetcher<T>({ queryKey: ['get', { url, ...params }] }),
    });
};

export const usePost = <T, R = void>(url: string) => {
    const queryClient = useQueryClient();
    return useMutation<R, Error, T | FormData>(
        {
            mutationFn: (body: T | FormData) => {
                const headers = body instanceof FormData 
                    ? {} 
                    : { 'Content-Type': 'application/json' };
                
                return fetcher<R>({
                    queryKey: ['post', { url }],
                    method: 'POST',
                    // @ts-ignore
                    body,
                    headers,
                });
            },
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['get', { url }] });
            },
        }
    );
};

export const usePut = <T, R = void>(url: string) => {
    const queryClient = useQueryClient();
    return useMutation<R, Error, T>(
      {
        mutationFn: (body: any) => fetcher<R>({ queryKey: ['put', { url }], method: 'PUT', body }),
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['get', { url }] });
        },
      }
    );
  };


export const usePatch = <T, R = void>(url: string) => {
    const queryClient = useQueryClient();
    return useMutation<R, Error, T>(
        {
            mutationFn: (body: any) => fetcher<R>({ queryKey: ['patch', { url }], method: 'PATCH', body }),
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['get', { url }] });
            },
        }
    );
};

export const useDelete = <T>(url: string) => {
    const queryClient = useQueryClient();
    return useMutation<T, Error>(
        {
            mutationFn: () => fetcher<T>({ queryKey: ['delete', { url }], method: 'DELETE' }),
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['get', { url }] });
            },
        }
    );
};
