import { useState } from 'react';
import { Table, SIZE, DIVIDER } from "baseui/table-semantic";
import { Button, SIZE as ButtonSize } from "baseui/button";
import { Block } from "baseui/block";
import { Spinner } from "baseui/spinner";
import { FlexGrid } from "baseui/flex-grid";
import { useStyletron } from 'baseui';
import { Pagination, SIZE as PaginationSize } from "baseui/pagination";
import formatDate from '@/utils/formatDate';

const filterConstant = {
    ALL: 'all',
    SELECTED: 'approved'
};

const CandidateTable = ({ loading = false, data, totalPages=1, changeSelectedFilter, changePage }) => {
    const [filter, setFilter] = useState(filterConstant.ALL);
    const [css] = useStyletron();
    const [currentPage, setCurrentPage] = useState(1);

    const onFilterChange = (newFilter) => {
        if (newFilter === filter) {
            return;
        }
        setFilter(newFilter);
        setCurrentPage(1);
        if (typeof changeSelectedFilter === 'function') {
            changeSelectedFilter(newFilter)
        }
    };

    const onPageChange = (nextPage) => {
        const page = Math.min(Math.max(nextPage, 1), totalPages)
        setCurrentPage(page);
        if (typeof changePage === 'function') {
            changePage(page)
        }
    }

    // Function to get columns based on filter
    const getColumns = () => {
        if (filter === filterConstant.ALL) {
            return ["Email", "Name", "Job role", "Profile review", "MTI Status", "Onboarding status", "Uploaded on"];
        } else if (filter === filterConstant.SELECTED) {
            return ["Email", "Name", "Onboarding status", "Trial Start date", "Off boarded date", <span>Final Developer<br />Rate(USD)</span>, "Uploaded on"];
        }
    };

    // Function to get data based on filter
    const getFilteredData = () => {
        if (!data || !data.length) {
            return [];
        }
        if (filter === filterConstant.ALL) {
            return data.map((item) => ([
                item.email, item.name, item.job_title, item.application_review_status, item.mti_status, item.onboarding_status, formatDate(item.uploaded_date)
            ]));
        } else if (filter === filterConstant.SELECTED) {
            return data.map((item) => ([
                item.email, item.name, item.onboarding_status, formatDate(item.trial_start_date), formatDate(item.offboarded_date), item.final_developer_rate, formatDate(item.uploaded_date)
            ]));
        }
    };

    return (
        <FlexGrid flexDirection="column" gridGap="16px">
            <Block display="flex" flexDirection="row" flexWrap style={{ marginTop: '16px' }}>
                <Button
                    disabled={loading}
                    isSelected={filter === filterConstant.ALL}
                    size={ButtonSize.compact}
                    onClick={() => onFilterChange(filterConstant.ALL)}
                >
                    All candidates
                    {filter === filterConstant.ALL && loading ? (
                        <div style={{ marginLeft: '4px ' }}>
                            <Spinner $size='15px' />
                        </div>
                    ) : null}
                </Button>
                <Button
                    disabled={loading}
                    isSelected={filter === filterConstant.SELECTED}
                    size={ButtonSize.compact}
                    onClick={() => onFilterChange(filterConstant.SELECTED)}
                    overrides={{
                        BaseButton: {
                            style: {
                                marginLeft: '16px'
                            },
                        },
                    }}
                >
                    Selected
                    {filter === filterConstant.SELECTED && loading ? (
                        <div style={{ marginLeft: '4px ' }}>
                            <Spinner $size='15px' />
                        </div>
                    ) : null}
                </Button>
            </Block>
            <div className={css({
                overflowX: 'auto', // Enables horizontal scrolling
                width: '100%', // Ensure the container spans the full width
                whiteSpace: 'nowrap', // Prevent columns from wrapping
            })}>
                <Table
                    columns={getColumns()}
                    data={getFilteredData()}
                    size={SIZE.spacious}
                    emptyMessage={<h1>No data</h1>}
                    divider={DIVIDER.grid}
                    isLoading={loading}
                    loadingMessage={<Spinner $size='30px' />}
                    overrides={{
                        TableHeadCell: {
                            style: {
                                backgroundColor: '#f0f0f0',
                                fontWeight: 'bold',
                            },
                        },
                    }}
                />
            </div>
            <Pagination
                size={PaginationSize.compact}
                numPages={totalPages}
                currentPage={currentPage}
                onPageChange={({ nextPage }) => onPageChange(nextPage)}
            />
        </FlexGrid>
    );
};

export default CandidateTable;
