import { Job } from '@/network/definitions/job';
import { useStore } from './store';

export interface JobStore {
    jobs: Job[];
    selectedJob?: Job;
    setJobs: (jobs: Job[]) => void;
    getJobById: (id: number) => Job | undefined;
}

const useJobStore = useStore<JobStore>(
    'job',
    true,
    (set, get) => ({
        jobs: [],
        selectedJob: undefined,
        setJobs: (jobs: Job[]) => {
            set({ jobs });
        },
        getJobById: (id: number) => {
            return get().jobs.find(job => job.id === id);
        },
    })
);

export default useJobStore;
