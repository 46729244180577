import { createTheme, lightThemePrimitives } from "baseui";
const primitives = {
    ...lightThemePrimitives,
    primaryFontFamily: 'Poppins, sans-serif',
    colors: {
        buttonPrimaryFill: '#0070f3',
        buttonPrimaryText: '#ffffff',
        buttonSecondaryFill: '#fff',
        buttonSecondaryText: '#333333',
        backgroundPrimary: "#F8F8F8",
        backgroundSecondary: '#fff',
        buttonPrimaryHover: '#1A4DA8',
    },
    typography: {
        font100: {
            fontFamily: 'Poppins, sans-serif',
        },
        font200: {
            fontFamily: 'Poppins, sans-serif',
        },
        font300: {
            fontFamily: 'Poppins, sans-serif',
        },
        font400: {
            fontFamily: 'Poppins, sans-serif',
        },
        font450: {
            fontFamily: 'Poppins, sans-serif',
        },
        font500: {
            fontFamily: 'Poppins, sans-serif',
        },
        font600: {
            fontFamily: 'Poppins, sans-serif',
        },
        font700: {
            fontFamily: 'Poppins, sans-serif',
        },
        font800: {
            fontFamily: 'Poppins, sans-serif',
        },
        font900: {
            fontFamily: 'Poppins, sans-serif',
        },
        font1000: {
            fontFamily: 'Poppins, sans-serif',
        },
        font1100: {
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 'bold',
            fontSize: '22px',
        }
    },
    overrides: {
        Button: {
            // Override the Base Web Button style
            style: {
                backgroundColor: '#0070f3',
                color: '#ffffff',
                ':hover': {
                    backgroundColor: '#ffffff', // Custom hover background color
                    color: '#0070f3', // Custom hover text color
                },
            },
        },
    },
};


export const theme = createTheme(primitives);
