import { useStore } from './store';
import { jwtDecode } from 'jwt-decode';
export interface AuthStore {
    isAuthenticated: () => boolean;
    token?: string;
    login: (token: string) => void;
    logout: () => void;
}

const useAuthStore = useStore<AuthStore>(
    'auth',
    true,
    (set, get) => ({
        login: (token: string) => set({ token }),
        logout: () => {
            localStorage.clear();
            set({ token: undefined })
            window.location.reload();
        },
        isAuthenticated: () => {
            const token = get().token;
            if (!token) {
                return false;
            }
            try {
                const decoded = jwtDecode(token);
                const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds since the epoch
                if (decoded.exp && decoded.exp > currentTime) {
                    return true;
                }
                return false;
            } catch (error) {
                console.error(`Error decoding token: ${error}`);
                return false;
            }
        },
    })
);

export default useAuthStore;