import { useState } from 'react';
import { StatefulInput } from "baseui/input";
import { Button } from "baseui/button";
import { HeadingSmall, ParagraphSmall } from "baseui/typography";
import { Card, StyledBody, StyledAction } from "baseui/card";
import { Block } from "baseui/block";
import { FormControl } from "baseui/form-control";
import useAuthStore from "@/store/auth";
import { usePost } from "@/hooks/apiHooks";
import { useRef } from "react";

interface PostData {
    email: string;
    password: string;
}

interface PostError {
    statusCode: number;
    message: {
        message: string
    };
}


interface PostResponse {
    token: string;
}

const Login = () => {
    const login = useAuthStore((state) => state.login);
    const navigate = useNavigate();
    const postMutation = usePost<PostData, PostResponse | PostError>("agency-api/login");

    const [error, setError] = useState<string | null>(null);

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const email = emailRef.current?.value;
        const password = passwordRef.current?.value;

        setError(null);
        if (email && password) {
            postMutation.mutate(
                {
                    email,
                    password,
                },
                {
                    onSuccess: (data) => {
                        if ("token" in data) {
                            login(data.token);
                            navigate("/home");
                        } else {
                            // Handle login failure (e.g., show a message to the user)
                            setError(data.message.message);
                        }
                    },
                    onError: (error) => {
                        // Handle error (e.g., show an error message to the user)
                        console.error("Login failed:", error);
                        setError("Login failed. Please try again.");
                    },
                }
            );
        }
    };

    return (
        <Block
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <Card
                overrides={{
                    Root: {
                        style: ({ $theme }) => ({
                            backgroundColor: $theme.colors.backgroundSecondary, // Customize the color
                            padding: $theme.sizing.scale600,
                            maxWidth: "400px",
                            width: "100%",
                        }),
                    },
                }}
            >
                <StyledBody>
                    <Block
                        width="100%"
                        overflow="hidden" // Ensure that the image doesn’t overflow
                    >
                        <img
                            src="/turing-logo.png"
                            alt="Turing"
                            style={{ width: "50%", height: "auto" }} // Ensure responsiveness
                        />
                    </Block>

                    <HeadingSmall>Agency Portal</HeadingSmall>

                    <form onSubmit={handleSubmit}>
                        <FormControl key="email" label="Email">
                            <StatefulInput
                                name="email"
                                type="email"
                                inputRef={emailRef}
                                placeholder="Enter your email"
                                required
                            />
                        </FormControl>

                        <FormControl label="Password">
                            <StatefulInput
                                name="password"
                                type="password"
                                inputRef={passwordRef}
                                placeholder="Enter your password"
                                required
                            />
                        </FormControl>

                        <StyledAction>
                            <Button
                                type="submit"
                                overrides={{
                                    BaseButton: {
                                        style: {
                                            width: "100%",
                                        },
                                    },
                                }}
                                isLoading={postMutation.isPending}
                            >
                                Login
                            </Button>


                            {error && (
                                <ParagraphSmall
                                    style={{
                                        color: "red",
                                        marginBottom: "scale400",
                                        textAlign: "center",
                                    }}
                                >
                                    {error}
                                </ParagraphSmall>
                            )}
                        </StyledAction>
                    </form>
                </StyledBody>
            </Card>
        </Block>
    );
};

export default Login;
